<h4>{{ 'device.find' | translate }} &#8594; {{ 'device.label1' | translate }} &#8594; {{ obj.id }}</h4>
<div class="row" *ngIf="identity && identity.user">
  <div class="col-lg-4">
    <div class="dash-box dash-box-4" [ngClass]="{'dash-box-selected': step == 'details'}" (click)="step = 'details'">
      <p class="ic" [hidden]="step == 'details'"><img src="/assets/portal/device.svg" alt=""></p>
      <p class="ic" [hidden]="step != 'details'"><img src="/assets/portal/white/device.svg" alt=""></p>
      <p>{{ 'device.label2' | translate }}</p>
    </div>
  </div>
  <div *ngIf="!hasHoldCharts" class="col-lg-4">
    <div class="dash-box dash-box-4" [ngClass]="{'dash-box-selected': step == 'statistics'}"
      (click)="step = 'statistics'">
      <p class="ic" [hidden]="step == 'statistics'"><img src="/assets/portal/statistiche.svg" alt=""></p>
      <p class="ic" [hidden]="step != 'statistics'"><img src="/assets/portal/white/statistiche.svg" alt=""></p>
      <p>{{ 'COMMON.statistics' | translate }}</p>
    </div>
  </div>
  <div *ngIf="hasHoldCharts" class="col-lg-4">
    <div class="dash-box dash-box-4" [ngClass]="{'dash-box-selected': step == 'chart'}" (click)="step = 'chart'">
      <p class="ic" [hidden]="step == 'chart'"><img src="/assets/portal/grafici.svg" alt=""></p>
      <p class="ic" [hidden]="step != 'chart'"><img src="/assets/portal/white/grafici.svg" alt=""></p>
      <p>{{ 'COMMON.graph' | translate }}</p>
    </div>
  </div>
  <!-- <div class="col-lg-4" *ngIf="identity.role == 'ROLE_ADMIN' || identity.role == 'ROLE_OEM'">
    <div class="dash-box dash-box-4" [ngClass]="{'dash-box-selected': step == 'tasks'}" (click)="step = 'tasks'">
      <p class="ic" [hidden]="step == 'tasks'"><img src="/assets/portal/storico.svg" alt=""></p>
      <p class="ic" [hidden]="step != 'tasks'"><img src="/assets/portal/white/storico.svg" alt=""></p>
      <p>{{ 'device.label3' | translate }}</p>
    </div>
  </div> -->
  <div class="col-lg-4"
    *ngIf="identity.role == 'ROLE_ADMIN' || identity.role == 'ROLE_ADMIN_SUPPORT'  || identity.role == 'ROLE_OEM' || identity.role == 'ROLE_SUPPORT'">
    <div class="dash-box dash-box-4" [ngClass]="{'dash-box-selected': step == 'download'}" (click)="step = 'download'">
      <p class="ic" [hidden]="step == 'download'"><img src="/assets/portal/download.svg" alt=""></p>
      <p class="ic" [hidden]="step != 'download'"><img src="/assets/portal/white/download.svg" alt=""></p>
      <p>{{ 'device.label5' | translate }}</p>
    </div>
  </div>
  <div class="col-lg-4" *ngIf="identity.role == 'ROLE_OEM'">
    <div class="dash-box dash-box-3" (click)="goToPortal()">
      <p class="ic"><img src="/assets/portal/controllo.svg" alt=""></p>
      <!-- <p class="ic"><img src="/assets/portal/white/controllo.svg" alt="" ></p> -->
      <p>{{ 'device.label6' | translate }}</p>
    </div>
  </div>
  <!-- <div class="col-lg-4" *ngIf="identity.role == 'ROLE_OEM'">
    <div class="dash-box dash-box-4" [ngClass]="{'dash-box-selected': step == 'ticketmaster'}"  (click)="step = 'ticketmaster'">
      <p class="ic" [hidden]="step == 'ticketmaster'"><img src="/assets/portal/messaggio.svg" alt="" ></p>
      <p class="ic" [hidden]="step != 'ticketmaster'"><img src="/assets/portal/white/messaggio.svg" alt="" ></p>
      <p>{{ 'device.label7' | translate }}</p>
    </div>
  </div> -->
</div>


<div class="block block-m-t" *ngIf="step == 'tasks'">
  <div class="row">
    <div class="col-lg-12">
      <task-list [queryIn]="{page: 1, thing_id: obj.id, orderBy:'created_at', order: 'DESC'}" mode="inline"></task-list>
    </div>
  </div>
</div>

<div class="block block-m-t" *ngIf="step == 'ticketmaster'">
  <div class="row">
    <div class="col-lg-12">
      <ticketmaster-edit paramsIn="{id:0}" mode="inline"></ticketmaster-edit>
    </div>
  </div>
</div>

<div class="block block-m-t" *ngIf="step == 'download'">
  <div class="row">
    <div class="col-lg-12 text-center">
      <h4>{{ 'device.text-download' | translate }}</h4>
      <p>&nbsp;</p>
      <p><button class="btn btn-info btn-lg" (click)="download()" type="button" [ladda]="downloading"
          [hidden]="downloadReady">{{ 'device.download1' | translate }}</button></p>
      <p [hidden]="downloadReady || !downloading"><small>{{ 'device.download2' | translate }}</small></p>
      <p [hidden]="!downloadReady"><a class="btn btn-success btn-lg" [href]="downloadUrl" target="_blank">{{
          'device.download3' | translate }}</a></p>
    </div>
  </div>
</div>

<div class="block block-m-t" *ngIf="step == 'chart'">
  <div class="row">
    <div class="col-lg-12">
      <div class="form-group">
        <div class="btn-group">
          <label for="organization" class="btn btn-default btn-auto">{{ 'device.room' | translate }} </label>
          <select class="form-control" id="organization" (change)="chartRoom($event.target.value, day)"
            [(ngModel)]="roomchart" name="roomchart">
            <option value="null">
              {{ 'device.select_room' | translate }}
            </option>
            <option *ngFor="let r of rooms" value="{{ r.SN }}">
              {{ r.name }}
            </option>
          </select>
        </div>
        <div class="btn-group">
          <label for="organization" class="btn btn-default btn-auto"> {{ 'device.day' | translate }} </label>
          <select class="form-control" id="day" (change)="chartRoom(roomchart, $event.target.value)" [(ngModel)]="day"
            name="day">
            <option value="week">
              {{ 'device.last_week' | translate }}
            </option>
            <option *ngFor="let d of days" value="{{ d }}">
              {{ d | CoreDatePipe : 'MMM d, y' }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12 text-center" *ngIf="chartLoading">
      <p> </p>
      <h3 class="text-center"><i class="fa fa-spinner fa-spin"></i></h3>
      <p> </p>
    </div>
    <div class="col-lg-12  text-center" *ngIf="!chartLoading && lineChartData && lineChartData.length">
      <canvas margin-top baseChart width="400" height="220" [datasets]="lineChartData" [labels]="lineChartLabels"
        [options]="lineChartOptions" [colors]="lineChartColors2" chartType="line"></canvas>
    </div>
    <div class="col-lg-12 text-center" *ngIf="!chartLoading && lineChartData2 && lineChartData.length">
      <canvas margin-top baseChart width="400" height="220" [datasets]="lineChartData2" [labels]="lineChartLabels"
        [options]="lineChartOptions2" chartType="line"></canvas>
    </div>
  </div>
</div>
<div class="block block-m-t" *ngIf="obj && obj.data && obj.data.err">
  <div class="row">
    <div class="col-lg-12 text-center">
      <p class="lead"><br><br>{{ 'device.no_config' | translate }}.<br><br></p>
      <p *ngIf="identity.role == 'ROLE_OEM'">
        <button class="btn btn-outline-info" (click)="addsupport = !addsupport">{{ 'device.addsupport' | translate
          }}</button> <span *ngIf="addsupportadded" class="text-success">{{ 'device.addsupportadded' | translate
          }}</span>
      </p>
    </div>
  </div>
</div>


<div class="block block-m-t overflow-x-scroll" *ngIf="step == 'statistics'">
  <statistics-view [deviceId]="obj.id"></statistics-view>
</div>

<div class="block block-m-t overflow-x-scroll" *ngIf="step == 'details'">



  <div class="row" *ngIf="obj.data && !obj.data.err">
    <div class="col-lg-6">
      <p class="mb-3">
        <strong>{{ obj?.data?.reported?.loc || ''}}</strong>
      </p>
      <p>
        ID: {{ obj.id }}
      </p>

      <p>
        {{ 'COMMON.create2' | translate }}: {{ obj.created_at | CoreDatePipe : 'MMM d, y' }}
      </p>
      <p>
        {{ 'device.label8' | translate }}: {{ obj.first_run_at | CoreDatePipe : 'MMM d, y' }}
      </p>
      <p>
        Setup: {{ obj.setup_at | CoreDatePipe : 'MMM d, y' }}
      </p>
      <!-- <p>
        {{ 'device.scadenza' | translate }}: {{ obj.expire_at | CoreDatePipe : 'MMM d, y' }}
      </p> -->
      <p>
        PIN/PUK: {{ obj.pin }}
      </p>

      <!-- <p>
        {{ 'device.place' | translate }}:   <span *ngIf="obj.data && obj.data.reported">
              {{ obj.data.reported.loc }}
            </span>
        <span *ngIf="obj.data && obj.data.error">
              {{ 'device.label10' | translate }}
            </span>
      </p> -->
      <p *ngIf="obj.latitude">
        {{ 'device.place2' | translate }}: {{obj.latitude}}, {{obj.longitude}}
      </p>
      <p *ngIf="identity.role == 'ROLE_OEM'">
        <button class="btn btn-outline-info" (click)="addsupport = !addsupport">{{ 'device.addsupport' | translate
          }}</button> <span *ngIf="addsupportadded" class="text-success">{{ 'device.addsupportadded' | translate
          }}</span>
      </p>
    </div>
    <div class="col-lg-3">
      <div class="block"
        *ngIf="obj.data && obj.data.reported && obj.data.reported.tExt > -500 && obj.data.reported.tExt < 500">
        <core-chart-gauge [title]="'chart.ext'" [label]="'temp'" [append]="'C°'" [value]="obj.data.reported.tExt / 10"
          [max]="40" [thick]="10" [type]="'semi'" [color]="'warning'"
          [schema]="{'danger': 33, 'warning': 28, 'info': 0}"></core-chart-gauge>
      </div>
    </div>
    <!-- <div class="col-lg-3">
      <div class="block" *ngIf="obj.data && obj.data.reported.groups &&  obj.data.reported.groups[0] &&  obj.data.reported.groups[0].rooms">
        <core-chart-gauge
          [title]="'chart.int'"
          [label]="obj.data.reported.groups[0].rooms[0].name"
          [append]="'C°'"
          [value]="obj.data.reported.groups[0].rooms[0].t / 10"
          [max]="40"
          [thick]="10"
          [type]="'semi'"
          [color]="'warning'"
          [schema]="{'danger': 33, 'warning': 28, 'info': 0}"
        ></core-chart-gauge>
      </div>
    </div> -->
  </div>


  <div *ngIf="addsupport">
    <h3> {{ 'device.addsupport' | translate }}</h3>
    <user-list mode="inline" [modeSelect]="true"
      [queryIn]="{organization_id: identity.user.organization.id, role: 'ROLE_SUPPORT'}"></user-list>
  </div>


  <table class="table table-bordered" *ngIf="obj.data && obj.data.reported && obj.data.reported.loc">
    <thead class="">
      <tr class="bg-lead">
        <th class="text-center " colspan="10">
          {{ 'device.group_room' | translate }}
        </th>
      </tr>

      <tr class="text-center">
        <th>
          {{ 'device.group' | translate }}
        </th>
        <th [hidden]="isMobile">
          {{ 'COMMON.mod' | translate }}
        </th>
        <th [hidden]="isMobile">
          {{ 'COMMON.status' | translate }}
        </th>

        <th [hidden]="isMobile">
          Comfort
        </th>
        <th>
          {{ 'COMMON.options' | translate }}
        </th>
      </tr>
    </thead>

    <tbody *ngFor="let group of obj.data.reported.groups">
      <tr class="text-center">
        <td>
          {{ group.name }}
        </td>
        <td [hidden]="isMobile">
          {{ groupModes[group.mode] }}
        </td>
        <td [hidden]="isMobile">
          {{ 'device.offStatus-' + groupOff[group.off] | translate }}
        </td>

        <td [hidden]="isMobile">
          {{ group.comfort }}%
        </td>
        <td>
          <button class="btn btn-sm btn-outline-info" (click)="group.show = !group.show">{{ 'device.sensors' | translate
            }}</button>
        </td>
      </tr>
      <tr [hidden]="!group.show">
        <td colspan="10">
          <table class="table table-sm table-striped" style="font-size:90%;border:0">
            <thead class="text-center">
              <tr style="background:#fff">
                <th>
                  {{ 'device.name_room' | translate }}
                </th>
                <th [hidden]="isMobile">
                  {{ 'device.serial' | translate }}
                </th>
                <th>
                  {{ 'COMMON.type' | translate }}
                </th>
                <th>
                  Temp
                </th>
                <th [hidden]="!isMobile">
                  {{'COMMON.humidityShort' | translate}}
                </th>
                <!--   <th [hidden]="isMobile">
              {{ 'COMMON.options' | translate }}
            </th> -->
              </tr>
            </thead>
            <tbody *ngFor="let room of group.rooms">
              <tr class="text-center">
                <td>
                  {{ room.name }}
                </td>
                <td [hidden]="isMobile">
                  #{{ room.SN }}
                </td>
                <td>
                  {{ 'roomTypes.' + room.type | translate }}
                </td>
                <td>
                  {{ room.t / 10 | number : '1.1-1' }}°C
                </td>
                <td [hidden]="!isMobile">
                  {{room.h}}%
                </td>
                <!--   <td [hidden]="isMobile">
              <button class="btn btn-sm btn-outline-info" (click)="showRoom(room)" >{{'COMMON.details' | translate}}</button>
            </td> -->
              </tr>
              <tr [hidden]="!room.show">
                <!--<td colspan="2" style="width:33%;overflow:hidden" *ngIf="room.telemetry && room.telemetry.t">
              <core-chart-base
                [title]="room.name + ' - storico t&h'"
                [colors]="['danger', 'info']"
                [labels]="room.labels"
                [values]="[room.telemetry.t, room.telemetry.h]"
                [legend] = "false"
              ></core-chart-base>

            </td>-->
                <td colspan="2" style="width:33%;padding-top:25px;overflow:hidden">
                  <core-chart-gauge [title]="'chart.temp'" [label]="' '" [append]="'C°'" [value]="room.t / 10"
                    [max]="40" [thick]="10" [type]="'semi'" [color]="'warning'"
                    [schema]="{'danger': 33, 'warning': 28, 'success': 0}"></core-chart-gauge>
                </td>
                <td colspan="2" style="width:33%;padding-top:25px;overflow:hidden">
                  <core-chart-gauge *ngIf="room.h > 2 && room.h < 100" [title]="'chart.hum'" [label]="' '"
                    [append]="'%'" [value]="room.h " [max]="100" [thick]="10" [type]="'semi'" [color]="'info'"
                    [schema]="{'primary': 80, 'info': 0}"></core-chart-gauge>
                </td>
                <td colspan="2" style="width:33%;padding-top:25px;overflow:hidden;text-align:center;"><br><br>
                  <button class="btn btn-default" (click)="step = 'chart'; chartRoom(room.SN)"> {{ 'COMMON.graph' |
                    translate }}</button>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>



  <table class="table table-bordered" *ngIf="users.length">
    <thead class="">
      <tr class="bg-lead">
        <th class="text-center " colspan="10">
          {{ 'user.title' | translate }}
        </th>
      </tr>

      <tr class="text-center">
        <th [ngClass]="{'mobile-col-user': isMobile}">
          {{ 'user.name' | translate }}
        </th>
        <th [hidden]="isMobile">
          {{ 'user.surname' | translate }}
        </th>
        <th [ngClass]="{'mobile-col-user': isMobile}">
          Email
        </th>
        <th [ngClass]="{'mobile-col-user': isMobile}">
          {{ 'user.role' | translate }}
        </th>
        <!-- <th>
        {{ 'user.sensor' | translate }}
      </th> -->
      </tr>
    </thead>

    <tbody *ngFor="let user of users">
      <tr class="text-center">
        <td [ngClass]="{'mobile-col-user wrap': isMobile}">
          {{ user.user.firstname }} <br [hidden]="!isMobile"><span [hidden]="!isMobile">{{ user.user.lastname }}</span>
        </td>
        <td [hidden]="isMobile">
          {{ user.user.lastname }}
        </td>
        <td [ngClass]="{'mobile-col-user wrap': isMobile}">
          {{ user.user.username }}
          <!-- <a routerLink="/user/edit/{{ user.user.id}}"><i class="fa fa-link"></i></a> -->
        </td>
        <td [ngClass]="{'mobile-col-user wrap': isMobile}">
          {{ 'roles' | CoreLabelPipe : user.user.role | translate }}
        </td>
        <!-- <td>
        <small *ngFor="let sensor of user.sensors">{{ sensor }}<br></small>
      </td> -->
      </tr>
    </tbody>
  </table>


  <!-- <p>
    * campo obbligatorio
  </p> -->


  <!-- <form #objForm="ngForm" [hidden]="!(edit || obj.id)" (ngSubmit)="save()">


          <div class="row">
            <div class="col-lg-6">
              <div class="form-group">
                <div class="form-group">
                  <label for="name">Devicename *</label>
                  <input
                    type="text" class="form-control" id="devicename"
                    required
                    [(ngModel)]="obj.devicename" name="devicename" >
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="form-group">
                <label for="channel">Ruolo *</label>
                <div>
                  <div class="btn-group" ngbRadioGroup name="channel" [(ngModel)]="obj.role">
                    <label ngbButtonLabel class="btn-info" *ngFor="let s of 'roles' | CoreLabelPipe">
                      <input ngbButton type="radio" [value]="s.id"> {{s.label | translate }}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="form-group">
                <div class="form-group">
                  <label for="firstname">Nome *</label>
                  <input
                    type="text" class="form-control" id="firstname"
                    required
                    [(ngModel)]="obj.firstname" name="firstname" >
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <div class="form-group">
                  <label for="lastname">Cognome *</label>
                  <input
                    type="text" class="form-control" id="lastname"
                    required
                    [(ngModel)]="obj.lastname" name="lastname" >
                </div>
              </div>
            </div>

          </div>



    <div class="form-group mt-3">
      <button type="submit" class="btn btn-success btn-lg" [disabled]="!objForm.valid || !obj.role  " [ladda]="saveLoading">Salva</button>
    </div>

  </form> -->
</div>